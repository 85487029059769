export const formatCurrency = (value: number | undefined | null): string => {
  if (value == null) return '-';
  return `$${value.toLocaleString()}`;
};

export const formatDate = (date: string): string => {
  return new Date(date).toLocaleDateString();
};

export const getStatusColor = (status: string): string => {
  switch (status) {
    case 'Won':
      return 'bg-green-100 text-green-800';
    case 'Negotiation':
      return 'bg-blue-100 text-blue-800';
    case 'Lost':
      return 'bg-red-100 text-red-800';
    case 'In Progress':
      return 'bg-yellow-100 text-yellow-800';
    default:
      return 'bg-gray-100 text-gray-800';
  }
};

export const generateSlug = (title: string): string => {
  return title
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/(^-|-$)/g, '');
};