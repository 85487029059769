import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { X } from 'lucide-react';
import { supabase } from '../../../lib/supabase';
import { useAuth } from '../../../contexts/AuthContext';
import { Lead, Customer } from '../../../types/crm';

interface LeadFormProps {
  lead?: Lead | null;
  onClose: () => void;
  onSave: () => void;
}

const leadStatuses = ['New', 'In Progress', 'Negotiation', 'Won', 'Lost'];
const leadCategories = ['AI', 'RydeZilla', 'IoT', 'Fullstack Apps', 'ICT', 'Custom'];
const taskTypes = ['Email', 'Meeting', 'Call', 'Visit'];

const LeadForm: React.FC<LeadFormProps> = ({ lead, onClose, onSave }) => {
  const { user } = useAuth();
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [formData, setFormData] = useState<Partial<Lead>>(
    lead || {
      lead_name: '',
      pitch_date: undefined,
      contact_person: undefined,
      phone_number: undefined,
      email: undefined,
      estimated_budget: '',
      po_amount: '',
      status: 'New',
      category: 'Custom',
      notes: '',
      task: undefined,
      customer_id: undefined
    }
  );

  useEffect(() => {
    const fetchCustomers = async () => {
      if (!user) return;
      
      const { data, error } = await supabase
        .from('customers')
        .select('*')
        .order('first_name', { ascending: true });

      if (!error && data) {
        setCustomers(data);
      }
    };
    fetchCustomers();
  }, [user]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!user) return;

    const data = {
      ...formData,
      user_id: user.id
    };

    if (lead) {
      const { error } = await supabase
        .from('leads')
        .update(data)
        .eq('id', lead.id);

      if (error) {
        console.error('Error updating lead:', error);
        return;
      }
    } else {
      const { error } = await supabase
        .from('leads')
        .insert([data]);

      if (error) {
        console.error('Error creating lead:', error);
        return;
      }
    }

    onSave();
    onClose();
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
    >
      <div className="bg-white rounded-lg p-8 max-w-2xl w-full max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-gray-900">
            {lead ? 'Edit Lead' : 'Add Lead'}
          </h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="md:col-span-2">
              <label className="block text-sm font-medium text-gray-700">Lead Name</label>
              <input
                type="text"
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#9b87f5] focus:ring focus:ring-[#9b87f5] focus:ring-opacity-50"
                value={formData.lead_name}
                onChange={(e) => setFormData({ ...formData, lead_name: e.target.value })}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Contact Person</label>
              <input
                type="text"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#9b87f5] focus:ring focus:ring-[#9b87f5] focus:ring-opacity-50"
                value={formData.contact_person}
                onChange={(e) => setFormData({ ...formData, contact_person: e.target.value })}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Phone Number</label>
              <input
                type="tel"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#9b87f5] focus:ring focus:ring-[#9b87f5] focus:ring-opacity-50"
                value={formData.phone_number}
                onChange={(e) => setFormData({ ...formData, phone_number: e.target.value })}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Email</label>
              <input
                type="email"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#9b87f5] focus:ring focus:ring-[#9b87f5] focus:ring-opacity-50"
                value={formData.email}
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Pitch Date</label>
              <input
                type="date"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#9b87f5] focus:ring focus:ring-[#9b87f5] focus:ring-opacity-50"
                value={formData.pitch_date}
                onChange={(e) => setFormData({ ...formData, pitch_date: e.target.value })}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Estimated Budget</label>
              <input
                type="number"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#9b87f5] focus:ring focus:ring-[#9b87f5] focus:ring-opacity-50"
                value={formData.estimated_budget || ''}
                onChange={(e) => setFormData({ ...formData, estimated_budget: parseFloat(e.target.value) })}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Customer</label>
              <select
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#9b87f5] focus:ring focus:ring-[#9b87f5] focus:ring-opacity-50"
                value={formData.customer_id || ''}
                onChange={(e) => setFormData({ ...formData, customer_id: e.target.value })}
              >
                <option value="">Select a customer</option>
                {customers.map((customer) => (
                  <option key={customer.id} value={customer.id}>
                    {customer.first_name} {customer.last_name} - {customer.company_name}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Category</label>
              <select
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#9b87f5] focus:ring focus:ring-[#9b87f5] focus:ring-opacity-50"
                value={formData.category}
                onChange={(e) => setFormData({ ...formData, category: e.target.value })}
              >
                {leadCategories.map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Task</label>
              <select
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#9b87f5] focus:ring focus:ring-[#9b87f5] focus:ring-opacity-50"
                value={formData.task || ''}
                onChange={(e) => setFormData({ ...formData, task: e.target.value })}
              >
                <option value="">Select a task</option>
                {taskTypes.map((task) => (
                  <option key={task} value={task}>
                    {task}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">PO Amount</label>
              <input
                type="number"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#9b87f5] focus:ring focus:ring-[#9b87f5] focus:ring-opacity-50"
                value={formData.po_amount || ''}
                onChange={(e) => setFormData({ ...formData, po_amount: parseFloat(e.target.value) })}
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">Status</label>
              <select
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#9b87f5] focus:ring focus:ring-[#9b87f5] focus:ring-opacity-50"
                value={formData.status}
                onChange={(e) => setFormData({ ...formData, status: e.target.value })}
              >
                {leadStatuses.map((status) => (
                  <option key={status} value={status}>
                    {status}
                  </option>
                ))}
              </select>
            </div>
          </div>
          
          <div className="md:col-span-2">
            <label className="block text-sm font-medium text-gray-700">Notes</label>
            <textarea
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#9b87f5] focus:ring focus:ring-[#9b87f5] focus:ring-opacity-50"
              rows={4}
              value={formData.notes || ''}
              onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
              placeholder="Add any additional notes about this lead..."
            />
          </div>

          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-[#9b87f5] text-white rounded-md hover:bg-[#8b77e5]"
            >
              {lead ? 'Update' : 'Create'}
            </button>
          </div>
        </form>
      </div>
    </motion.div>
  );
};

export default LeadForm;