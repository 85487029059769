import React, { useState, useEffect } from 'react';
import { Plus } from 'lucide-react';
import { supabase } from '../../../lib/supabase';
import { useAuth } from '../../../contexts/AuthContext';
import CustomerForm from './CustomerForm';
import { Customer } from '../../../types/crm';
import { SearchBar } from '../../../components/crm/SearchBar';
import { ExportButton } from '../../../components/crm/ExportButton';
import { DataTable } from '../../../components/crm/DataTable';
import { exportToExcel } from '../../../utils/exportUtils';
import { formatDate } from '../../../utils/formatUtils';
import { CustomerActions } from './CustomerActions';

const CustomersPage = () => {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [editingCustomer, setEditingCustomer] = useState<Customer | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      fetchCustomers();
    }
  }, [user]);

  const fetchCustomers = async () => {
    if (!user) return;
    const { isAdmin } = await supabase.rpc('check_admin_access');

    const { data, error } = await supabase
      .from('customers')
      .select('*')
      .order('created_at', { ascending: false });

    if (error) {
      console.error('Error fetching customers:', error);
      return;
    }

    setCustomers(data || []);
  };

  const handleDelete = async (id: string) => {
    if (!user) return;

    const { error } = await supabase
      .from('customers')
      .delete()
      .eq('id', id)
      .eq('user_id', user.id);

    if (error) {
      console.error('Error deleting customer:', error);
      return;
    }

    await fetchCustomers();
  };

  const handleExport = () => {
    const data = filteredCustomers.map(customer => ({
      'First Name': customer.first_name,
      'Last Name': customer.last_name,
      'Company': customer.company_name || '',
      'Phone': customer.phone_number || '',
      'Address': customer.address || '',
      'Country': customer.country,
      'Created': formatDate(customer.created_at)
    }));
    exportToExcel(data, 'Customers', 'customers');
  };

  const filteredCustomers = customers.filter(customer =>
    `${customer.first_name} ${customer.last_name}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
    customer.company_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    customer.phone_number?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const columns = [
    { key: 'name', header: 'Name', render: (_, row) => `${row.first_name} ${row.last_name}` },
    { key: 'company_name', header: 'Company', render: (value) => value || '-' },
    { key: 'phone_number', header: 'Contact', render: (value) => value || '-' },
    { key: 'country', header: 'Country' },
    { key: 'created_at', header: 'Created', render: (value) => formatDate(value) },
    {
      key: 'actions',
      header: '',
      render: (_, row) => (
        <CustomerActions
          customer={row}
          onEdit={() => {
            setEditingCustomer(row);
            setIsFormOpen(true);
          }}
          onDelete={() => handleDelete(row.id)}
        />
      )
    }
  ];

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center space-x-4">
          <SearchBar
            value={searchTerm}
            onChange={setSearchTerm}
            placeholder="Search customers..."
          />
          <ExportButton onClick={handleExport} />
        </div>
        <button
          onClick={() => {
            setEditingCustomer(null);
            setIsFormOpen(true);
          }}
          className="flex items-center px-4 py-2 bg-[#9b87f5] text-white rounded-lg hover:bg-[#8b77e5] transition-colors"
        >
          <Plus size={20} className="mr-2" />
          Add Customer
        </button>
      </div>

      <DataTable
        columns={columns}
        data={filteredCustomers}
        emptyMessage="No customers found"
      />

      {isFormOpen && (
        <CustomerForm
          customer={editingCustomer}
          onClose={() => {
            setIsFormOpen(false);
            setEditingCustomer(null);
          }}
          onSave={fetchCustomers}
        />
      )}
    </div>
  );
};

export default CustomersPage;