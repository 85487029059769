export const partners = [
  {
    name: 'Aljedad',
    logo: '/Aljedad-logo.svg',
    alt: 'Aljedad Logo'
  },
  {
    name: 'Bombaslo Tech',
    logo: '/Bombaslo-tech.svg',
    alt: 'Bombaslo Tech Logo'
  },
  {
    name: 'Kuwait Supreme',
    logo: '/kuwaiti.png',
    alt: 'Kuwait Supreme Logo'
  },
  {
    name: 'Green Shield',
    logo: '/green-shield.png',
    alt: 'Green Shield Logo'
  },
  {
    name: 'Zain',
    logo: '/Zain.png',
    alt: 'Zain Logo'
  }
];