import React from 'react';
import { motion } from 'framer-motion';
import SolutionsGrid from './SolutionsGrid';
import SolutionFeatures from './SolutionFeatures';

const SolutionsIntro = () => {
  return (
    <section className="py-20 bg-gradient-to-b from-[#F6F6F7] to-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold text-[#1A1F2C] mb-4">
            Solutions for Tomorrow's Challenges
          </h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Empower your business with our comprehensive suite of cutting-edge solutions designed to drive growth, efficiency, and innovation in the digital age.
          </p>
        </motion.div>
        <SolutionsGrid />
        <SolutionFeatures />
      </div>
    </section>
  );
};

export default SolutionsIntro;