import React from 'react';
import { Shield, ShieldOff, Trash2 } from 'lucide-react';

interface UserActionsProps {
  userId: string;
  isAdmin: boolean;
  onDelete: (userId: string) => void;
  onToggleAdmin: (userId: string, isAdmin: boolean) => void;
}

const UserActions: React.FC<UserActionsProps> = ({
  userId,
  isAdmin,
  onDelete,
  onToggleAdmin
}) => {
  const handleToggleAdmin = async () => {
    try {
      await onToggleAdmin(userId, !isAdmin);
    } catch (error) {
      console.error('Error toggling admin status:', error);
    }
  };

  return (
    <div className="flex justify-end space-x-3">
      <button
        onClick={handleToggleAdmin}
        className={`${
          isAdmin ? 'text-purple-600' : 'text-gray-600'
        } hover:text-purple-900`}
        title={isAdmin ? 'Remove admin privileges' : 'Grant admin privileges'}
      >
        {isAdmin ? <ShieldOff size={18} /> : <Shield size={18} />}
      </button>
      <button
        onClick={() => onDelete(userId)}
        className="text-red-600 hover:text-red-900"
        title="Delete user"
      >
        <Trash2 size={18} />
      </button>
    </div>
  );
};

export default UserActions;