import React from 'react';
import { Edit, Trash2 } from 'lucide-react';
import { Lead } from '../../../types/crm';

interface LeadActionsProps {
  lead: Lead;
  onEdit: () => void;
  onDelete: () => void;
}

export const LeadActions: React.FC<LeadActionsProps> = ({ onEdit, onDelete }) => {
  return (
    <div className="flex justify-end space-x-4">
      <button
        onClick={onEdit}
        className="text-[#9b87f5] hover:text-[#8b77e5]"
      >
        <Edit size={18} />
      </button>
      <button
        onClick={onDelete}
        className="text-red-600 hover:text-red-900"
      >
        <Trash2 size={18} />
      </button>
    </div>
  );
};