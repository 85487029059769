import React from 'react';
import { motion, useAnimationControls } from 'framer-motion';
import { ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import SolutionsHeroSlider from '../components/SolutionsHeroSlider';
import SolutionsIntro from '../components/home/SolutionsIntro';
import Partners from '../components/home/Partners';

const TypewriterText = ({ text }: { text: string }) => {
  const controls = useAnimationControls();
  const [displayedText, setDisplayedText] = React.useState('');

  React.useEffect(() => {
    let currentIndex = 0;
    const interval = setInterval(() => {
      if (currentIndex <= text.length) {
        setDisplayedText(text.slice(0, currentIndex));
        currentIndex++;
      } else {
        clearInterval(interval);
      }
    }, 100);

    return () => clearInterval(interval);
  }, [text]);

  return (
    <span className="relative">
      {displayedText}
      <motion.span
        initial={{ opacity: 1 }}
        animate={{ opacity: 0 }}
        transition={{ duration: 0.5, repeat: Infinity, repeatType: "reverse" }}
        className="absolute -right-4 top-0"
      >
        |
      </motion.span>
    </span>
  );
};

const Home = () => {
  return (
    <div className="bg-[#1A1F2C]">
      {/* Hero Section */}
      <section className="relative h-screen flex items-center">
        <div className="absolute inset-0">
          <img
            src="https://images.unsplash.com/photo-1451187580459-43490279c0fa?auto=format&fit=crop&q=80"
            alt="Technology Background"
            className="w-full h-full object-cover opacity-20"
          />
        </div>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
          <h1 className="text-4xl md:text-6xl font-bold text-white mb-6">
            <TypewriterText text="Building the Future with" />
            <span className="text-[#9b87f5]"> 
              <TypewriterText text="Intelligent Solutions" />
            </span>
          </h1>
            <p className="text-xl text-gray-300 mb-8 max-w-3xl mx-auto">
              Code Nest delivers cutting-edge technology solutions that transform businesses and drive innovation across industries.
            </p>
            <Link
              to="/contact"
              className="inline-flex items-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-[#9b87f5] hover:bg-[#8b77e5] transition-colors"
            >
              Get Started
              <ArrowRight className="ml-2" size={20} />
            </Link>
          </motion.div>
        </div>
      </section>

      {/* Solutions Hero Slider */}
      <SolutionsHeroSlider />

      {/* Solutions Section */}
      <SolutionsIntro />

      {/* Partners Section */}
      <Partners />
    </div>
  );
};

export default Home;