import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import PartnerLogo from './PartnerLogo';
import 'swiper/css';

interface Partner {
  name: string;
  logo: string;
  alt: string;
}

interface PartnersCarouselProps {
  partners: Partner[];
}

const PartnersCarousel: React.FC<PartnersCarouselProps> = ({ partners }) => {
  return (
    <Swiper
      modules={[Autoplay]}
      spaceBetween={30}
      slidesPerView={1}
      loop={true}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true
      }}
      breakpoints={{
        640: { slidesPerView: 2 },
        1024: { slidesPerView: 3 }
      }}
      className="w-full py-4"
    >
      {partners.map((partner) => (
        <SwiperSlide key={partner.name}>
          <PartnerLogo {...partner} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default PartnersCarousel;