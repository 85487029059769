import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';
import { IconMap } from '../../../utils/icon-map';

interface SolutionCardProps {
  icon: string;
  title: string;
  description: string;
  path: string;
  index: number;
}

const SolutionCard: React.FC<SolutionCardProps> = ({ icon, title, description, path, index }) => {
  const IconComponent = IconMap[icon];

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      whileHover={{ scale: 1.02 }}
      className="bg-white p-8 rounded-lg shadow-lg group"
    >
      <div className="flex items-center justify-center mb-6">
        {IconComponent && (
          <IconComponent 
            className="w-12 h-12 text-[#9b87f5] transition-transform duration-300 group-hover:scale-110" 
          />
        )}
      </div>
      <h3 className="text-xl font-bold text-[#1A1F2C] mb-4">{title}</h3>
      <p className="text-gray-600 mb-6">{description}</p>
      <Link
        to={path}
        className="inline-flex items-center text-[#9b87f5] hover:text-[#8b77e5] transition-colors"
      >
        Learn More
        <ArrowRight className="ml-2 transition-transform duration-300 group-hover:translate-x-1" size={16} />
      </Link>
    </motion.div>
  );
};

export default SolutionCard;