import React from 'react';
import ProductLayout from '../ProductLayout';
import AIB2BLayout from './AIB2BLayout';

const AIB2BSolutions = () => {
  return (
    <ProductLayout
      title="AI B2B Solutions"
      description="Empower your business with intelligent automation and data-driven insights"
      backgroundImage="https://images.unsplash.com/photo-1677442136019-21780ecad995?auto=format&fit=crop&q=80"
    >
      <AIB2BLayout />
    </ProductLayout>
  );
};

export default AIB2BSolutions;