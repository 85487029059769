import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import CRMNavigation from './CRMNavigation';
import { useAuth } from '../../contexts/AuthContext';
import { LogOut } from 'lucide-react';

const CRMLayout = () => {
  const { signOut } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    await signOut();
    navigate('/login');
  };

  return (
    <div className="min-h-screen bg-[#F6F6F7]">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <div className="flex justify-between items-center mb-8">
            <h1 className="text-3xl font-bold text-[#1A1F2C]">Customer Relationship Management</h1>
            <button
              onClick={handleLogout}
              className="flex items-center px-4 py-2 bg-[#9b87f5] text-white rounded-lg hover:bg-[#8b77e5] transition-colors"
            >
              <LogOut size={20} className="mr-2" />
              Logout
            </button>
          </div>
          <CRMNavigation />
          <div className="mt-6 bg-white rounded-lg shadow-lg p-6">
            <Outlet />
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default CRMLayout;