import React from 'react';
import { fullstackSolutions } from '../../../data/fullstack-solutions';
import ProductGrid from '../components/ProductGrid';
import SectionHeader from '../components/SectionHeader';

const FullstackLayout = () => {
  return (
    <div className="py-12 bg-gradient-to-b from-white to-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <SectionHeader 
          title="End-to-End Web Solutions"
          description="Build powerful, scalable web applications with our comprehensive fullstack development solutions."
        />
        <ProductGrid products={fullstackSolutions} />
      </div>
    </div>
  );
};

export default FullstackLayout;