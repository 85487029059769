import React from 'react';
import { UserManagementProps } from '../../../types/admin';
import UserTableHeader from '../../../components/admin/UserTableHeader';
import UserTableRow from '../../../components/admin/UserTableRow';

const UserManagement: React.FC<UserManagementProps> = ({
  users,
  onDeleteUser,
  onToggleAdmin
}) => {
  return (
    <div className="bg-white rounded-lg shadow overflow-hidden">
      <table className="min-w-full divide-y divide-gray-200">
        <UserTableHeader />
        <tbody className="bg-white divide-y divide-gray-200">
          {users.map((user) => (
            <UserTableRow
              key={user.id}
              user={user}
              onDeleteUser={onDeleteUser}
              onToggleAdmin={onToggleAdmin}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserManagement;