import React from 'react';
import { motion } from 'framer-motion';
import { IoTBenefit } from '../../../../types/iot';
import BenefitCard from './BenefitCard';

interface BenefitsGridProps {
  benefits: IoTBenefit[];
}

const BenefitsGrid: React.FC<BenefitsGridProps> = ({ benefits }) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
      {benefits.map((benefit, index) => (
        <motion.div
          key={benefit.id}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: index * 0.1 }}
        >
          <BenefitCard benefit={benefit} />
        </motion.div>
      ))}
    </div>
  );
};

export default BenefitsGrid;