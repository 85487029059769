import React from 'react';
import { aiSolutions } from '../../../data/ai-solutions';
import ProductGrid from './components/ProductGrid';
import SectionHeader from './components/SectionHeader';

const AIB2BLayout = () => {
  return (
    <div className="py-12 bg-gradient-to-b from-white to-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <SectionHeader 
          title="Transform Your Business with AI"
          description="Our comprehensive suite of AI-powered solutions helps businesses automate processes, gain insights, and drive growth through intelligent technology."
        />
        <ProductGrid products={aiSolutions} />
      </div>
    </div>
  );
};

export default AIB2BLayout;