import React from 'react';
import { AdminUser } from '../../types/admin';
import { formatDate } from '../../utils/formatUtils';
import UserRole from './UserRole';
import UserActions from './UserActions';

interface UserTableRowProps {
  user: AdminUser;
  onDeleteUser: (userId: string) => void;
  onToggleAdmin: (userId: string, isAdmin: boolean) => void;
}

const UserTableRow: React.FC<UserTableRowProps> = ({ user, onDeleteUser, onToggleAdmin }) => (
  <tr>
    <td className="px-6 py-4 whitespace-nowrap">
      <div className="text-sm text-gray-900">{user.email}</div>
    </td>
    <td className="px-6 py-4 whitespace-nowrap">
      <UserRole role={user.role} />
    </td>
    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
      {formatDate(user.created_at)}
    </td>
    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
      <UserActions
        userId={user.id}
        isAdmin={user.role === 'admin'}
        onDelete={onDeleteUser}
        onToggleAdmin={onToggleAdmin}
      />
    </td>
  </tr>
);

export default UserTableRow;