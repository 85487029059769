import React from 'react';
import { motion } from 'framer-motion';
import { Zap, Shield, TrendingUp } from 'lucide-react';

const features = [
  {
    icon: Zap,
    title: 'Rapid Implementation',
    description: 'Get up and running quickly with our streamlined deployment process'
  },
  {
    icon: Shield,
    title: 'Enterprise Security',
    description: 'Built-in security features to protect your business data'
  },
  {
    icon: TrendingUp,
    title: 'Scalable Growth',
    description: 'Solutions that grow with your business needs'
  }
];

const SolutionFeatures = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-16">
      {features.map((feature, index) => (
        <motion.div
          key={feature.title}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: index * 0.1 + 0.5 }}
          className="text-center p-6 bg-white rounded-lg shadow-lg hover:shadow-xl transition-shadow"
        >
          <div className="flex justify-center mb-4">
            <feature.icon className="w-12 h-12 text-[#9b87f5]" />
          </div>
          <h3 className="text-xl font-semibold text-gray-900 mb-3">{feature.title}</h3>
          <p className="text-gray-600">{feature.description}</p>
        </motion.div>
      ))}
    </div>
  );
};

export default SolutionFeatures;