import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Loader } from 'lucide-react';
import { AdminUser } from '../../../types/admin';
import { useAdmin } from '../../../hooks/useAdmin';
import UserManagement from './UserManagement';
import { fetchUsers, deleteUser, toggleAdminStatus } from '../../../services/adminService';

const AdminPage = () => {
  const [users, setUsers] = useState<AdminUser[]>([]);
  const [loading, setLoading] = useState(true);
  const { isAdmin, loading: adminLoading } = useAdmin();

  useEffect(() => {
    if (isAdmin) {
      loadUsers();
    }
  }, [isAdmin]);

  const loadUsers = async () => {
    try {
      setLoading(true);
      const data = await fetchUsers();
      setUsers(data);
    } catch (error) {
      console.error('Error loading users:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteUser = async (userId: string) => {
    try {
      await deleteUser(userId);
      await loadUsers();
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const handleToggleAdmin = async (userId: string, isAdmin: boolean) => {
    try {
      await toggleAdminStatus(userId, isAdmin);
      await loadUsers();
    } catch (error) {
      console.error('Error toggling admin status:', error);
    }
  };

  if (adminLoading || loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <Loader className="w-8 h-8 animate-spin text-purple-600" />
      </div>
    );
  }

  if (!isAdmin) {
    return <Navigate to="/crm/customers" replace />;
  }

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold text-gray-900">User Management</h2>
      <UserManagement
        users={users}
        onDeleteUser={handleDeleteUser}
        onToggleAdmin={handleToggleAdmin}
      />
    </div>
  );
};

export default AdminPage;