import React from 'react';
import { motion } from 'framer-motion';

interface PartnerLogoProps {
  name: string;
  logo: string;
  alt: string;
}

const PartnerLogo: React.FC<PartnerLogoProps> = ({ name, logo, alt }) => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.9 }}
      whileInView={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
      className="flex justify-center items-center h-24 px-8"
    >
      <img
        src={logo}
        alt={alt}
        className="max-h-16 w-auto object-contain transition-transform duration-300 hover:scale-105"
        onError={(e) => {
          console.error(`Error loading image for ${name}:`, e);
          e.currentTarget.style.display = 'none';
        }}
      />
    </motion.div>
  );
};

export default PartnerLogo;