import { supabase } from '../lib/supabase';
import { AdminUser } from '../types/admin';

interface AuthUser {
  id: string;
  email: string;
  created_at: string;
}

export const fetchUsers = async (): Promise<AdminUser[]> => {
  const { data: users, error: usersError } = await supabase
    .from('users')
    .select('*');

  if (usersError) throw usersError;

  const { data: adminUsers, error: adminError } = await supabase
    .from('admin_lookup')
    .select('user_id');

  if (adminError) throw adminError;

  if (!users) return [];

  return users.map(user => ({
    id: user.id,
    email: user.email || '',
    created_at: user.created_at,
    role: adminUsers?.some(admin => admin.user_id === user.id) ? 'admin' : 'user'
  }));
};

export const deleteUser = async (userId: string): Promise<void> => {
  const { error } = await supabase.rpc('delete_user', { user_id: userId });
  if (error) throw error;
};

export const toggleAdminStatus = async (userId: string, isAdmin: boolean): Promise<void> => {
  const { error } = await supabase
    .rpc('toggle_admin_status', {
      target_user_id: userId,
      make_admin: isAdmin
    });
  
  if (error) throw error;
};