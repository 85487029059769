import { Product } from '../types';

export const fullstackSolutions: Product[] = [
  {
    id: 'interactive-web',
    title: 'Interactive Web Applications',
    description: 'Create engaging web experiences with rich user interfaces, real-time features, and seamless interactions.',
    icon: 'Laptop',
    path: '/contact',
    category: 'fullstack'
  },
  {
    id: 'asset-manager-web',
    title: 'Asset Manager Web Application',
    description: 'Comprehensive web-based system for tracking, managing, and optimizing your business assets efficiently.',
    icon: 'Database',
    path: '/contact',
    category: 'fullstack'
  },
  {
    id: 'ecommerce-web',
    title: 'E-commerce Web Applications',
    description: 'Build powerful online stores with secure payments, inventory management, and customer analytics.',
    icon: 'ShoppingCart',
    path: '/contact',
    category: 'fullstack'
  },
  {
    id: 'canteen-manager',
    title: 'Canteen Manager',
    description: 'Digital solution for managing cafeterias with ordering systems, inventory tracking, and payment processing.',
    icon: 'Utensils',
    path: '/contact',
    category: 'fullstack'
  },
  {
    id: 'restaurant-pos',
    title: 'Restaurant POS',
    description: 'Modern point-of-sale system for restaurants with table management, order tracking, kitchen display system, and integrated payments.',
    icon: 'Store',
    path: '/contact',
    category: 'fullstack'
  }
];