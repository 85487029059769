import React from 'react';
import { motion } from 'framer-motion';
import PartnersCarousel from './PartnersCarousel';
import { partners } from './data/partners';

const Partners = () => {
  return (
    <section className="py-20 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="text-center mb-12"
        >
          <h2 className="text-3xl font-bold text-gray-900 mb-4">Our Partners</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Working together with industry leaders to deliver exceptional solutions
          </p>
        </motion.div>

        <PartnersCarousel partners={partners} />
      </div>
    </section>
  );
};

export default Partners;