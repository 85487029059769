import * as XLSX from 'xlsx';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

export const exportToExcel = (data: any[], sheetName: string, fileName: string) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
  XLSX.writeFile(workbook, `${fileName}.xlsx`);
};

export const exportToPDF = (
  title: string,
  summaryData: { label: string; value: string }[],
  tableData: { headers: string[]; rows: any[][] }[]
) => {
  const doc = new jsPDF();
  
  doc.setFontSize(20);
  doc.text(title, 20, 20);
  
  doc.setFontSize(12);
  summaryData.forEach((item, index) => {
    doc.text(`${item.label}: ${item.value}`, 20, 40 + (index * 10));
  });
  
  let yPosition = 80;
  tableData.forEach((table, index) => {
    if (index > 0) {
      doc.addPage();
      yPosition = 20;
    }
    
    doc.autoTable({
      head: [table.headers],
      body: table.rows,
      startY: yPosition,
      margin: { top: 20 }
    });
  });
  
  doc.save(`${title.toLowerCase().replace(/\s+/g, '_')}.pdf`);
};