import React from 'react';
import { Download } from 'lucide-react';

interface ExportButtonProps {
  onClick: () => void;
  label?: string;
}

export const ExportButton: React.FC<ExportButtonProps> = ({ onClick, label = 'Export' }) => {
  return (
    <button
      onClick={onClick}
      className="flex items-center px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
    >
      <Download size={20} className="mr-2" />
      {label}
    </button>
  );
};