import { IoTBenefit } from '../types';

export const iotBenefits: IoTBenefit[] = [
  {
    id: 'efficiency',
    title: 'Enhanced Operational Efficiency',
    description: 'Optimize resource utilization and streamline processes with real-time monitoring and automated controls. Reduce manual intervention and improve productivity across operations.',
    icon: 'Zap',
    image: 'https://images.unsplash.com/photo-1504384764586-bb4cdc1707b0?auto=format&fit=crop&q=80'
  },
  {
    id: 'insights',
    title: 'Data-Driven Decision Making',
    description: 'Transform raw data into actionable insights with advanced analytics. Make informed decisions based on real-time data and predictive analysis.',
    icon: 'LineChart',
    image: 'https://images.unsplash.com/photo-1551288049-bebda4e38f71?auto=format&fit=crop&q=80'
  },
  {
    id: 'cost',
    title: 'Cost Optimization',
    description: 'Reduce operational costs through predictive maintenance, optimized resource allocation, and automated workflow management. Maximize ROI with smart resource utilization.',
    icon: 'DollarSign',
    image: 'https://images.unsplash.com/photo-1460925895917-afdab827c52f?auto=format&fit=crop&q=80'
  },
  {
    id: 'safety',
    title: 'Enhanced Safety & Security',
    description: 'Improve workplace safety with real-time monitoring and early warning systems. Prevent incidents before they occur with predictive safety measures.',
    icon: 'Shield',
    image: 'https://images.unsplash.com/photo-1563770660941-20978e870e26?auto=format&fit=crop&q=80'
  },
  {
    id: 'scalability',
    title: 'Future-Ready Scalability',
    description: 'Easily scale your IoT infrastructure as your business grows. Our flexible solutions adapt to your evolving needs while maintaining performance.',
    icon: 'Expand',
    image: 'https://images.unsplash.com/photo-1558346490-a72e53ae2d4f?auto=format&fit=crop&q=80'
  },
  {
    id: 'remote',
    title: 'Remote Management',
    description: 'Control and monitor your operations from anywhere in the world. Enable efficient remote work and management with secure access to your IoT ecosystem.',
    icon: 'Globe',
    image: 'https://images.unsplash.com/photo-1451187580459-43490279c0fa?auto=format&fit=crop&q=80'
  }
];