import React, { useState, useEffect } from 'react';
import { Plus, Edit2, Trash2, Eye } from 'lucide-react';
import { supabase } from '../../../lib/supabase';
import { useAuth } from '../../../contexts/AuthContext';
import { BlogPost } from '../../../types/blog';
import BlogPostForm from './BlogPostForm';
import { DataTable } from '../../../components/crm/DataTable';
import { SearchBar } from '../../../components/crm/SearchBar';
import { formatDate } from '../../../utils/formatUtils';
import { useAdmin } from '../../../hooks/useAdmin';

const BlogPostsPage = () => {
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [editingPost, setEditingPost] = useState<BlogPost | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const { user } = useAuth();
  const { isAdmin } = useAdmin();

  useEffect(() => {
    if (user) {
      fetchPosts();
    }
  }, [user]);

  const fetchPosts = async () => {
    const { data, error } = await supabase
      .from('blog_posts')
      .select('*')
      .order('created_at', { ascending: false });

    if (error) {
      console.error('Error fetching posts:', error);
      return;
    }

    setPosts(data || []);
  };

  const handleDelete = async (id: string) => {
    if (!isAdmin) {
      console.error('Only admins can delete posts');
      return;
    }

    const { error } = await supabase
      .from('blog_posts')
      .delete()
      .eq('id', id);

    if (error) {
      console.error('Error deleting post:', error);
      return;
    }

    await fetchPosts();
  };

  const filteredPosts = posts.filter(post =>
    post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    post.excerpt?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const columns = [
    { 
      key: 'title', 
      header: 'Title',
      render: (value: string, row: BlogPost) => (
        <div>
          <div className="font-medium text-gray-900">{value}</div>
          {row.excerpt && (
            <div className="text-sm text-gray-500 truncate">{row.excerpt}</div>
          )}
        </div>
      )
    },
    {
      key: 'status',
      header: 'Status',
      render: (value: string) => (
        <span className={`px-2 py-1 text-sm font-medium rounded-full ${
          value === 'published' 
            ? 'bg-green-100 text-green-800' 
            : 'bg-yellow-100 text-yellow-800'
        }`}>
          {value.charAt(0).toUpperCase() + value.slice(1)}
        </span>
      )
    },
    {
      key: 'created_at',
      header: 'Created',
      render: (value: string) => formatDate(value)
    },
    {
      key: 'published_at',
      header: 'Published',
      render: (value: string) => value ? formatDate(value) : '-'
    },
    {
      key: 'actions',
      header: '',
      render: (_, row: BlogPost) => (
        <div className="flex justify-end space-x-4">
          <button
            onClick={() => {
              setEditingPost(row);
              setIsFormOpen(true);
            }}
            className="text-[#9b87f5] hover:text-[#8b77e5]"
            title="Edit post"
          >
            <Edit2 size={18} />
          </button>
          {isAdmin && (
            <button
              onClick={() => handleDelete(row.id)}
              className="text-red-600 hover:text-red-900"
              title="Delete post"
            >
              <Trash2 size={18} />
            </button>
          )}
          {row.status === 'published' && (
            <a
              href={`/blog/${row.slug}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:text-blue-900"
              title="View published post"
            >
              <Eye size={18} />
            </a>
          )}
        </div>
      )
    }
  ];

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center space-x-4">
          <SearchBar
            value={searchTerm}
            onChange={setSearchTerm}
            placeholder="Search blog posts..."
          />
        </div>
        <button
          onClick={() => {
            setEditingPost(null);
            setIsFormOpen(true);
          }}
          className="flex items-center px-4 py-2 bg-[#9b87f5] text-white rounded-lg hover:bg-[#8b77e5] transition-colors"
        >
          <Plus size={20} className="mr-2" />
          Add Blog Post
        </button>
      </div>

      <DataTable
        columns={columns}
        data={filteredPosts}
        emptyMessage="No blog posts found"
      />

      {isFormOpen && (
        <BlogPostForm
          post={editingPost}
          onClose={() => {
            setIsFormOpen(false);
            setEditingPost(null);
          }}
          onSave={fetchPosts}
        />
      )}
    </div>
  );
};

export default BlogPostsPage;