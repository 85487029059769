import React from 'react';
import { motion } from 'framer-motion';
import { CheckCircle } from 'lucide-react';
import type { IoTBenefit } from '../../../../types/iot';

interface BenefitCardProps {
  benefit: IoTBenefit;
}

const BenefitCard: React.FC<BenefitCardProps> = ({ benefit }) => {
  return (
    <motion.div
      whileHover={{ scale: 1.02 }}
      className="bg-white rounded-lg shadow-lg overflow-hidden"
    >
      <div className="h-48 relative">
        <img
          src={benefit.image}
          alt={benefit.title}
          className="w-full h-full object-cover"
        />
      </div>
      <div className="p-6">
        <div className="flex items-start gap-3">
          <CheckCircle className="w-6 h-6 text-[#9b87f5] flex-shrink-0 mt-1" />
          <div>
            <h3 className="text-lg font-semibold text-gray-900 mb-2">
              {benefit.title}
            </h3>
            <p className="text-gray-600">
              {benefit.description}
            </p>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default BenefitCard;