import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { X, ChevronDown, FileText, Send } from 'lucide-react';
import { Menu } from '@headlessui/react';
import { supabase } from '../../../lib/supabase';
import { useAuth } from '../../../contexts/AuthContext';
import { BlogPost, BlogFormData } from '../../../types/blog';
import { generateSlug } from '../../../utils/formatUtils';

const statusOptions = [
  { value: 'draft', label: 'Draft', icon: FileText },
  { value: 'published', label: 'Published', icon: Send }
];

interface BlogPostFormProps {
  post?: BlogPost | null;
  onClose: () => void;
  onSave: () => void;
}

const BlogPostForm: React.FC<BlogPostFormProps> = ({ post, onClose, onSave }) => {
  const { user } = useAuth();
  const [formData, setFormData] = useState<BlogFormData>(
    post ? {
      title: post.title,
      content: post.content,
      excerpt: post.excerpt,
      featured_image: post.featured_image,
      status: post.status
    } : {
      title: '',
      content: '',
      excerpt: '',
      featured_image: '',
      status: 'draft'
    }
  );

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!user) return;

    const slug = post?.slug || generateSlug(formData.title);
    const now = new Date().toISOString();
    
    const data = {
      ...formData,
      slug,
      author_id: user.id,
      published_at: formData.status === 'published' ? now : null
    };

    if (post) {
      const { error } = await supabase
        .from('blog_posts')
        .update(data)
        .eq('id', post.id);

      if (error) {
        console.error('Error updating post:', error);
        return;
      }
    } else {
      const { error } = await supabase
        .from('blog_posts')
        .insert([data]);

      if (error) {
        console.error('Error creating post:', error);
        return;
      }
    }

    onSave();
    onClose();
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
    >
      <div className="bg-white rounded-lg p-8 max-w-4xl w-full max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold text-gray-900">
            {post ? 'Edit Blog Post' : 'Create Blog Post'}
          </h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">Title</label>
            <input
              type="text"
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#9b87f5] focus:ring focus:ring-[#9b87f5] focus:ring-opacity-50"
              value={formData.title}
              onChange={(e) => setFormData({ ...formData, title: e.target.value })}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Featured Image URL</label>
            <input
              type="url"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#9b87f5] focus:ring focus:ring-[#9b87f5] focus:ring-opacity-50"
              value={formData.featured_image}
              onChange={(e) => setFormData({ ...formData, featured_image: e.target.value })}
              placeholder="https://example.com/image.jpg"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Excerpt</label>
            <textarea
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#9b87f5] focus:ring focus:ring-[#9b87f5] focus:ring-opacity-50"
              rows={3}
              value={formData.excerpt}
              onChange={(e) => setFormData({ ...formData, excerpt: e.target.value })}
              placeholder="Brief summary of the blog post..."
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Content</label>
            <textarea
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-[#9b87f5] focus:ring focus:ring-[#9b87f5] focus:ring-opacity-50"
              rows={10}
              value={formData.content}
              onChange={(e) => setFormData({ ...formData, content: e.target.value })}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Status</label>
            <Menu as="div" className="relative">
              <Menu.Button className="w-full flex items-center justify-between px-4 py-2 text-sm border rounded-md border-gray-300 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-[#9b87f5] focus:ring-opacity-50">
                <div className="flex items-center">
                  {(() => {
                    const option = statusOptions.find(opt => opt.value === formData.status);
                    const Icon = option?.icon;
                    return Icon ? (
                      <Icon
                      className="w-4 h-4 mr-2 text-gray-500"
                      aria-hidden="true"
                      />
                    ) : null;
                  })()}
                  {statusOptions.find(option => option.value === formData.status)?.label}
                </div>
                <ChevronDown className="w-4 h-4 ml-2 text-gray-500" aria-hidden="true" />
              </Menu.Button>
              <Menu.Items className="absolute z-10 w-full mt-1 bg-white rounded-md shadow-lg border border-gray-200 focus:outline-none">
                <div className="py-1">
                  {statusOptions.map((option) => (
                    <Menu.Item key={option.value}>
                      {({ active }) => (
                        <button
                          type="button"
                          className={`${
                            active ? 'bg-[#9b87f5] text-white' : 'text-gray-900'
                          } flex items-center w-full px-4 py-2 text-sm`}
                          onClick={() => setFormData({ ...formData, status: option.value as 'draft' | 'published' })}
                        >
                          <option.icon
                            className={`w-4 h-4 mr-2 ${active ? 'text-white' : 'text-gray-500'}`}
                            aria-hidden="true"
                          />
                          {option.label}
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Menu>
          </div>

          <div className="flex justify-end space-x-4">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-[#9b87f5] text-white rounded-md hover:bg-[#8b77e5]"
            >
              {post ? 'Update' : 'Create'}
            </button>
          </div>
        </form>
      </div>
    </motion.div>
  );
};

export default BlogPostForm;