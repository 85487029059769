export const mainSolutions = [
  {
    id: 'ai',
    icon: 'Brain',
    title: 'AI B2B Solutions',
    description: 'Transform your operations with intelligent automation and data-driven insights powered by advanced AI technology.',
    path: '/products/ai-b2b'
  },
  {
    id: 'iot',
    icon: 'Globe',
    title: 'IoT Solutions',
    description: 'Connect and optimize your physical assets with our comprehensive IoT platform for enhanced operational efficiency.',
    path: '/products/iot-solutions'
  },
  {
    id: 'fullstack',
    icon: 'Code',
    title: 'Fullstack Solutions',
    description: 'Build powerful web applications with our end-to-end development solutions for modern digital experiences.',
    path: '/products/fullstack'
  },
  {
    id: 'ict',
    icon: 'Network',
    title: 'ICT Services',
    description: 'Strengthen your technology infrastructure with our comprehensive ICT services and expert support.',
    path: '/products/ict-services'
  }
];