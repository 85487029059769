import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import Layout from './components/layout/Layout';
import LoginPage from './pages/auth/LoginPage';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import AIB2BSolutions from './pages/products/ai-b2b';
import IoTSolutions from './pages/products/iot-solutions';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import FullstackSolutions from './pages/products/fullstack';
import ICTServicesSolutions from './pages/products/ict-services';
import CustomersPage from './pages/crm/customers/CustomersPage';
import LeadsPage from './pages/crm/leads/LeadsPage';
import ReportsPage from './pages/crm/reports/ReportsPage';
import AdminPage from './pages/crm/admin/AdminPage';
import BlogPostsPage from './pages/crm/blog/BlogPostsPage';
import CRMLayout from './pages/crm/CRMLayout';
import PrivateRoute from './components/PrivateRoute';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:slug" element={<BlogPost />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/products/ai-b2b" element={<AIB2BSolutions />} />
            <Route path="/products/iot-solutions" element={<IoTSolutions />} />
            <Route path="/products/fullstack" element={<FullstackSolutions />} />
            <Route path="/products/ict-services" element={<ICTServicesSolutions />} />
            
            <Route path="/crm" element={<PrivateRoute><CRMLayout /></PrivateRoute>}>
              <Route path="customers" element={<CustomersPage />} />
              <Route path="leads" element={<LeadsPage />} />
              <Route path="reports" element={<ReportsPage />} />
              <Route path="admin" element={<AdminPage />} />
              <Route path="blog" element={<BlogPostsPage />} />
            </Route>
          </Routes>
        </Layout>
      </Router>
    </AuthProvider>
  );
}

export default App;