import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ArrowLeft, Calendar, User } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { BlogPost } from '../types/blog';
import { formatDate } from '../utils/formatUtils';

const BlogPostDetail = () => {
  const { slug } = useParams();
  const [post, setPost] = useState<BlogPost | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchPost();
  }, [slug]);

  const fetchPost = async () => {
    try {
      const { data, error } = await supabase
        .from('blog_posts')
        .select('*')
        .eq('slug', slug)
        .eq('status', 'published')
        .single();

      if (error) throw error;
      setPost(data);
    } catch (error) {
      console.error('Error fetching blog post:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-[#1A1F2C] to-[#2A2F3C] flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#9b87f5]"></div>
      </div>
    );
  }

  if (!post) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-[#1A1F2C] to-[#2A2F3C] flex items-center justify-center">
        <div className="text-center px-4">
          <h1 className="text-4xl font-bold text-white mb-4">Post Not Found</h1>
          <p className="text-gray-300 mb-8">The blog post you're looking for doesn't exist or has been removed.</p>
          <Link
            to="/blog"
            className="inline-flex items-center px-6 py-3 bg-[#9b87f5] text-white rounded-lg hover:bg-[#8b77e5] transition-colors"
          >
            <ArrowLeft className="mr-2" size={20} />
            Back to Blog
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#1A1F2C] to-[#2A2F3C] py-20">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <Link
            to="/blog"
            className="inline-flex items-center text-gray-300 hover:text-[#9b87f5] mb-8"
          >
            <ArrowLeft className="mr-2" size={20} />
            Back to Blog
          </Link>

          {post.featured_image && (
            <img
              src={post.featured_image}
              alt={post.title}
              className="w-full h-[400px] object-cover rounded-lg mb-8"
            />
          )}

          <h1 className="text-4xl md:text-5xl font-bold text-white mb-6">
            {post.title}
          </h1>

          <div className="flex items-center space-x-6 text-gray-300 mb-8">
            <div className="flex items-center">
              <Calendar size={20} className="mr-2" />
              {formatDate(post.published_at || post.created_at)}
            </div>
            <div className="flex items-center">
              <User size={20} className="mr-2" />
              Author
            </div>
          </div>

          {post.excerpt && (
            <div className="text-xl text-gray-300 mb-8 font-medium">
              {post.excerpt}
            </div>
          )}

          <div className="prose prose-invert prose-lg max-w-none text-gray-300">
            <div dangerouslySetInnerHTML={{ __html: post.content }} />
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default BlogPostDetail;