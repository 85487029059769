import React from 'react';
import { motion } from 'framer-motion';

const IoTIntro = () => {
  return (
    <section className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          className="prose prose-lg max-w-none"
        >
          <p className="text-gray-700 leading-relaxed">
            The Internet of Things (IoT) represents a transformative technological ecosystem that connects physical devices with digital intelligence. Our IoT solutions enable businesses to harness the power of connected devices, real-time data analytics, and automated decision-making to drive operational efficiency and innovation. From smart manufacturing to intelligent infrastructure, we deliver end-to-end IoT solutions that create measurable business value.
          </p>
        </motion.div>
      </div>
    </section>
  );
};

export default IoTIntro;