import React, { useState, useEffect } from 'react';
import { Plus } from 'lucide-react';
import { supabase } from '../../../lib/supabase';
import { useAuth } from '../../../contexts/AuthContext';
import LeadForm from './LeadForm';
import { Lead } from '../../../types/crm';
import { SearchBar } from '../../../components/crm/SearchBar';
import { ExportButton } from '../../../components/crm/ExportButton';
import { DataTable } from '../../../components/crm/DataTable';
import { StatusBadge } from '../../../components/crm/StatusBadge';
import { exportToExcel } from '../../../utils/exportUtils';
import { formatDate, formatCurrency } from '../../../utils/formatUtils';
import { LeadActions } from './LeadActions';

const LeadsPage = () => {
  const [leads, setLeads] = useState<Lead[]>([]);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [editingLead, setEditingLead] = useState<Lead | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      fetchLeads();
    }
  }, [user]);

  const fetchLeads = async () => {
    if (!user) return;

    const { data, error } = await supabase
      .from('leads')
      .select('*')
      .order('created_at', { ascending: false });

    if (error) {
      console.error('Error fetching leads:', error);
      return;
    }

    setLeads(data || []);
  };

  const handleDelete = async (id: string) => {
    if (!user) return;

    const { error } = await supabase
      .from('leads')
      .delete()
      .eq('id', id);

    if (error) {
      console.error('Error deleting lead:', error);
      return;
    }

    await fetchLeads();
  };

  const handleExport = () => {
    const data = filteredLeads.map(lead => ({
      'Lead Name': lead.lead_name,
      'Contact Person': lead.contact_person || '',
      'Phone': lead.phone_number || '',
      'Email': lead.email || '',
      'Task': lead.task || '',
      'Estimated Budget': formatCurrency(lead.estimated_budget),
      'Category': lead.category,
      'PO Amount': formatCurrency(lead.po_amount),
      'Status': lead.status,
      'Created': formatDate(lead.created_at)
    }));
    exportToExcel(data, 'Leads', 'leads');
  };

  const filteredLeads = leads.filter(lead =>
    lead.lead_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    lead.contact_person?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    lead.phone_number?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const columns = [
    { key: 'lead_name', header: 'Lead Name' },
    {
      key: 'contact',
      header: 'Contact',
      render: (_, row) => (
        <div>
          <div className="text-sm text-gray-900">{row.contact_person || '-'}</div>
          <div className="text-sm text-gray-500">
            {row.email && (
              <a 
                href={`mailto:${row.email}`} 
                className="hover:text-[#9b87f5] inline-block mb-1"
              >
                {row.email}
              </a>
            )}
          </div>
          {row.task && (
            <div className="text-xs px-2 py-0.5 bg-blue-100 text-blue-800 rounded-full inline-block">
              {row.task}
            </div>
          )}
          {row.notes && (
            <div className="text-sm text-gray-500 truncate max-w-xs" title={row.notes}>
              {row.notes}
            </div>
          )}
        </div>
      )
    },
    {
      key: 'budget',
      header: 'Budget',
      render: (_, row) => (
        <div>
          <div className="text-sm text-gray-900">{formatCurrency(row.estimated_budget)}</div>
          {row.po_amount && (
            <div className="text-sm text-green-600">PO: {formatCurrency(row.po_amount)}</div>
          )}
        </div>
      )
    },
    {
      key: 'category',
      header: 'Category',
      render: (_, row) => (
        <span className="px-2 py-1 text-sm font-medium bg-gray-100 text-gray-800 rounded-full">
          {row.category}
        </span>
      )
    },
    {
      key: 'status',
      header: 'Status',
      render: (_, row) => <StatusBadge status={row.status} />
    },
    {
      key: 'created_at',
      header: 'Created',
      render: (value) => formatDate(value)
    },
    {
      key: 'actions',
      header: '',
      render: (_, row) => (
        <LeadActions
          lead={row}
          onEdit={() => {
            setEditingLead(row);
            setIsFormOpen(true);
          }}
          onDelete={() => handleDelete(row.id)}
        />
      )
    }
  ];

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center space-x-4">
          <SearchBar
            value={searchTerm}
            onChange={setSearchTerm}
            placeholder="Search leads..."
          />
          <ExportButton onClick={handleExport} />
        </div>
        <button
          onClick={() => {
            setEditingLead(null);
            setIsFormOpen(true);
          }}
          className="flex items-center px-4 py-2 bg-[#9b87f5] text-white rounded-lg hover:bg-[#8b77e5] transition-colors"
        >
          <Plus size={20} className="mr-2" />
          Add Lead
        </button>
      </div>

      <DataTable
        columns={columns}
        data={filteredLeads}
        emptyMessage="No leads found"
      />

      {isFormOpen && (
        <LeadForm
          lead={editingLead}
          onClose={() => {
            setIsFormOpen(false);
            setEditingLead(null);
          }}
          onSave={fetchLeads}
        />
      )}
    </div>
  );
};

export default LeadsPage;