import React from 'react';
import SolutionCard from './SolutionCard';
import { mainSolutions } from '../../../data/main-solutions';

const SolutionsGrid = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
      {mainSolutions.map((solution, index) => (
        <SolutionCard
          key={solution.id}
          {...solution}
          index={index}
        />
      ))}
    </div>
  );
};

export default SolutionsGrid;