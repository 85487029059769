import React from 'react';
import { NavLink } from 'react-router-dom';
import { Users, Briefcase, PieChart, Settings, FileText } from 'lucide-react';
import { useAdmin } from '../../hooks/useAdmin';

const CRMNavigation = () => {
  const { isAdmin, isLoaded } = useAdmin();

  const navItems = [
    { path: '/crm/customers', icon: Users, label: 'Customers' },
    { path: '/crm/leads', icon: Briefcase, label: 'Leads' },
    { path: '/crm/reports', icon: PieChart, label: 'Reports' },
    ...(isLoaded && isAdmin ? [
      { path: '/crm/blog', icon: FileText, label: 'Blog' },
      { path: '/crm/admin', icon: Settings, label: 'Admin' }
    ] : [])
  ];

  if (!isLoaded) {
    return (
      <nav className="bg-gradient-to-r from-[#1A1F2C] to-[#2A2F3C] shadow-lg rounded-lg p-4">
        <div className="h-12 animate-pulse bg-gray-700/50 rounded-lg"></div>
      </nav>
    );
  }

  return (
    <nav className="bg-gradient-to-r from-[#1A1F2C] to-[#2A2F3C] shadow-lg rounded-lg p-4">
      <ul className="flex space-x-6">
        {navItems.map((item) => (
          <li key={item.path}>
            <NavLink
              to={item.path}
              className={({ isActive }) =>
                `flex items-center px-4 py-2 rounded-md transition-colors ${
                  isActive
                    ? 'bg-[#9b87f5] text-white shadow-lg transform scale-105'
                    : 'text-gray-300 hover:bg-white/10'
                }`
              }
            >
              <item.icon className="w-5 h-5 mr-3" />
              {item.label}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default CRMNavigation;