import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';
import type { Product } from '../types';
import { IconMap } from '../utils/icon-map';

interface ProductCardProps {
  product: Product;
}

const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
  const IconComponent = IconMap[product.icon];

  return (
    <motion.div
      whileHover={{ scale: 1.02 }}
      className="bg-white p-8 rounded-lg shadow-lg h-full flex flex-col relative overflow-hidden hover:shadow-xl transition-shadow duration-300"
    >
      <div className="flex items-center justify-center mb-6 relative z-10">
        {IconComponent && (
          <IconComponent className="w-12 h-12 text-[#9b87f5] transition-transform group-hover:scale-110" />
        )}
      </div>
      <h3 className="text-xl font-bold text-[#1A1F2C] mb-4 line-clamp-2">
        {product.title}
      </h3>
      <p className="text-gray-600 mb-6 flex-grow line-clamp-3">
        {product.description}
      </p>
      <Link
        to={product.path}
        className="text-[#9b87f5] hover:text-[#8b77e5] inline-flex items-center mt-auto transition-all duration-300"
      >
        Learn More
        <ArrowRight className="ml-2 transition-transform duration-300 group-hover:translate-x-1" size={16} />
      </Link>
    </motion.div>
  );
};

export default ProductCard;