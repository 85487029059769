import React, { useState, useEffect } from 'react';
import { DollarSign, Users, TrendingUp, FileText } from 'lucide-react';
import { supabase } from '../../../lib/supabase';
import { useAuth } from '../../../contexts/AuthContext';
import { Customer, Lead } from '../../../types/crm';
import { StatCard } from '../../../components/crm/reports/StatCard';
import { DataTable } from '../../../components/crm/reports/DataTable';
import { ExportButtons } from '../../../components/crm/reports/ExportButtons';
import { exportToExcel, exportToPDF } from '../../../utils/exportUtils';
import { formatCurrency } from '../../../utils/formatUtils';

const ReportsPage = () => {
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [leads, setLeads] = useState<Lead[]>([]);
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      fetchData();
    }
  }, [user]);

  const fetchData = async () => {
    if (!user) return;

    const [customersResponse, leadsResponse] = await Promise.all([
      supabase.from('customers').select('*').eq('user_id', user.id),
      supabase.from('leads').select('*').eq('user_id', user.id)
    ]);

    if (customersResponse.error) console.error('Error fetching customers:', customersResponse.error);
    if (leadsResponse.error) console.error('Error fetching leads:', leadsResponse.error);

    setCustomers(customersResponse.data || []);
    setLeads(leadsResponse.data || []);
  };

  const stats = {
    totalCustomers: customers.length,
    totalLeads: leads.length,
    totalPipeline: leads.reduce((sum, lead) => sum + (lead.estimated_budget || 0), 0),
    conversionRate: leads.length > 0 
      ? ((leads.filter(lead => lead.status === 'Won').length / leads.length) * 100).toFixed(1)
      : '0'
  };

  const handleExportExcel = () => {
    const data = {
      customers: customers.map(c => ({
        'Name': `${c.first_name} ${c.last_name}`,
        'Company': c.company_name || '-',
        'Country': c.country
      })),
      leads: leads.map(l => ({
        'Lead Name': l.lead_name,
        'Status': l.status,
        'Value': formatCurrency(l.estimated_budget)
      }))
    };
    exportToExcel(data.customers, 'Customers', 'crm_report');
  };

  const handleExportPDF = () => {
    const summaryData = [
      { label: 'Total Customers', value: stats.totalCustomers.toString() },
      { label: 'Total Leads', value: stats.totalLeads.toString() },
      { label: 'Pipeline Value', value: formatCurrency(stats.totalPipeline) },
      { label: 'Conversion Rate', value: `${stats.conversionRate}%` }
    ];

    const tableData = [
      {
        headers: ['Name', 'Company', 'Country'],
        rows: customers.slice(0, 5).map(c => [
          `${c.first_name} ${c.last_name}`,
          c.company_name || '-',
          c.country
        ])
      },
      {
        headers: ['Lead Name', 'Status', 'Value'],
        rows: leads.slice(0, 5).map(l => [
          l.lead_name,
          l.status,
          formatCurrency(l.estimated_budget)
        ])
      }
    ];

    exportToPDF('CRM Report', summaryData, tableData);
  };

  return (
    <div className="space-y-8">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <StatCard title="Total Customers" value={stats.totalCustomers} Icon={Users} />
        <StatCard title="Total Leads" value={stats.totalLeads} Icon={FileText} delay={0.1} />
        <StatCard title="Pipeline Value" value={formatCurrency(stats.totalPipeline)} Icon={DollarSign} delay={0.2} />
        <StatCard title="Conversion Rate" value={`${stats.conversionRate}%`} Icon={TrendingUp} delay={0.3} />
      </div>

      <ExportButtons onExportExcel={handleExportExcel} onExportPDF={handleExportPDF} />

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <DataTable
          title="Recent Customers"
          columns={[
            { header: 'Name', accessor: 'name' },
            { header: 'Company', accessor: 'company' },
            { header: 'Country', accessor: 'country' }
          ]}
          data={customers.slice(0, 5).map(c => ({
            name: `${c.first_name} ${c.last_name}`,
            company: c.company_name || '-',
            country: c.country
          }))}
        />

        <DataTable
          title="Recent Leads"
          columns={[
            { header: 'Lead Name', accessor: 'name' },
            { header: 'Status', accessor: 'status' },
            { header: 'Value', accessor: 'value' }
          ]}
          data={leads.slice(0, 5).map(l => ({
            name: l.lead_name,
            status: l.status,
            value: formatCurrency(l.estimated_budget)
          }))}
        />
      </div>
    </div>
  );
};

export default ReportsPage;