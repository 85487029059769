import React from 'react';

interface UserRoleProps {
  role: string;
}

const UserRole: React.FC<UserRoleProps> = ({ role }) => (
  <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
    role === 'admin' ? 'bg-purple-100 text-purple-800' : 'bg-gray-100 text-gray-800'
  }`}>
    {role}
  </span>
);

export default UserRole;