import React from 'react';
import { Download, FileText } from 'lucide-react';

interface ExportButtonsProps {
  onExportExcel: () => void;
  onExportPDF: () => void;
}

export const ExportButtons: React.FC<ExportButtonsProps> = ({ onExportExcel, onExportPDF }) => {
  return (
    <div className="flex justify-end space-x-4">
      <button
        onClick={onExportExcel}
        className="flex items-center px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
      >
        <Download size={20} className="mr-2" />
        Export to Excel
      </button>
      <button
        onClick={onExportPDF}
        className="flex items-center px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors"
      >
        <FileText size={20} className="mr-2" />
        Export to PDF
      </button>
    </div>
  );
};